<template>
  <div v-if="signUpAuthorized">
    <form @submit.prevent="onsubmit()">
      <va-input
        class="mb-3"
        v-model="form.operatorName"
        type="text"
        label="Nom de l'agence"
        :error="!!operatorNameErrors.length"
        :error-messages="operatorNameErrors"
      />

      <va-input
        class="mb-3"
        v-model="form.guideName"
        type="text"
        label="Votre nom"
        :error="!!guideNameErrors.length"
        :error-messages="guideNameErrors"
      />

      <va-input
        class="mb-3"
        v-model="form.email"
        type="email"
        :label="$t('auth.email')"
        :error="!!emailErrors.length"
        :error-messages="emailErrors"
      />

      <va-input
        class="mb-3"
        v-model="form.password"
        type="password"
        :label="$t('auth.password')"
        :error="!!passwordErrors.length"
        :error-messages="passwordErrors"
      />

      <div class="auth-layout__options d-flex align--center justify--space-between">
        <router-link class="ml-1 link" :to="{name: 'recover-password'}">
          {{$t('auth.recover_password')}}
        </router-link>
      </div>

      <div class="d-flex justify--center mt-3">
        <va-button @click="onsubmit" class="my-0">{{ $t('auth.sign_up') }}</va-button>
      </div>
    </form>
  </div>
  <div v-else>
    Les inscriptions sont fermées pour le moment.
  </div>
</template>

<script>
export default {
  name: 'signup',
  data () {
    return {
      signUpAuthorized: false,
      form: {
        email: '',
        password: '',
        operatorName: '',
        guideName: '',
        agreedToTerms: false,
      },
      emailErrors: [],
      passwordErrors: [],
      operatorNameErrors: [],
      guideNameErrors: [],
    }
  },
  methods: {
      async onsubmit () {
        console.log('Processing form')
        this.emailErrors = this.form.email ? [] : ['Email is required']
        this.passwordErrors = this.form.password ? [] : ['Password is required']
        this.operatorNameErrors = this.form.operatorName ? [] : ['Operator Name is required']
        this.guideNameErrors = this.form.guideName ? [] : ['Name is required']
        if (!this.formReady) {
          console.log('Form not ready')
          return
        }
        console.log('Form ready')
        try {
          console.log('Submitting Form')
          const operators = await this.axios.post('/api/operators', this.form)
          console.log(operators)
          await this.$router.push({ name: 'dashboard' })
        } catch (e) {
          console.log(e)
        }
    },
  },
  computed: {
    formReady () {
      return !(this.emailErrors.length || this.operatorNameErrors.length || this.guideNameErrors.length || this.passwordErrors.length)
    },
  },
}
</script>

<style lang="scss">
</style>
